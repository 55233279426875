@font-face {
  font-family: 'Social';
  font-weight: normal;
  font-style: normal;
  font-display: block;
  src: url("/theme/assets/fonts/social_media.woff2") format("woff2"), url("/theme/assets/fonts/social_media.woff") format("woff"); }

.icon-social {
  font-style: normal;
  font-family: 'Social'; }
  .icon-social.icon-check:before {
    content: "\e901"; }
  .icon-social.icon-facebook:before {
    content: "\f09a"; }
  .icon-social.icon-twitter:before {
    content: "\f099"; }
  .icon-social.icon-envelope:before {
    content: "\f0e0"; }
  .icon-social.icon-linkedin:before {
    content: "\f0e1"; }
  .icon-social.icon-youtube-play:before {
    content: "\f16a"; }
  .icon-social.icon-instagram:before {
    content: "\f16d"; }
  .icon-social.icon-google:before {
    content: "\f1a0"; }
  .icon-social.icon-behance:before {
    content: "\f1b4"; }
  .icon-social.icon-pinterest-p:before {
    content: "\f231"; }
  .icon-social.icon-whatsapp:before {
    content: "\f232"; }
  .icon-social.icon-vimeo:before {
    content: "\f27d"; }

* {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: optional;
  font-family: 'Source Sans Pro', sans-serif; }

html {
  font-size: 62.5%;
  background-color: black; }

body {
  background-color: white; }

@media (min-width: 768px) {
  .plate--container {
    padding-left: 6.2rem;
    padding-right: 6.2rem;
    max-width: unset; } }

*, :after, :before {
  box-sizing: border-box; }

picture,
picture img {
  display: block; }

picture source {
  display: block;
  width: 100%;
  height: 100%; }

body {
  font-size: 1.5rem;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between; }

.body__inner {
  overflow: hidden; }

.body__bottom {
  margin-top: auto; }

.full-link-btn {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 6; }

.fl-container {
  display: flex;
  align-items: center; }

.jc-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.jc-between {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.jc-end {
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.jc-start {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.jc-around {
  display: flex;
  align-items: center;
  justify-content: space-around; }

.ai-start {
  display: flex;
  align-items: flex-start; }

.fl-row {
  flex-direction: row; }

.fl-column {
  flex-direction: column; }

.fl-wrap {
  flex-flow: row wrap; }

.bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

/* Colors */
/* shadow */
/* Fonts */
/* Breakpoints */
/* Animation speed */
/* Swiper */
/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000; }

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1; }

.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap; }

.swiper-container-multirow-column > .swiper-wrapper {
  flex-wrap: wrap;
  flex-direction: column; }

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform; }

.swiper-slide-invisible-blank {
  visibility: hidden; }

/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto; }

.swiper-container-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height; }

/* 3D Effects */
.swiper-container-3d {
  perspective: 1200px; }
  .swiper-container-3d .swiper-wrapper,
  .swiper-container-3d .swiper-slide,
  .swiper-container-3d .swiper-slide-shadow-left,
  .swiper-container-3d .swiper-slide-shadow-right,
  .swiper-container-3d .swiper-slide-shadow-top,
  .swiper-container-3d .swiper-slide-shadow-bottom,
  .swiper-container-3d .swiper-cube-shadow {
    transform-style: preserve-3d; }
  .swiper-container-3d .swiper-slide-shadow-left,
  .swiper-container-3d .swiper-slide-shadow-right,
  .swiper-container-3d .swiper-slide-shadow-top,
  .swiper-container-3d .swiper-slide-shadow-bottom {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10; }
  .swiper-container-3d .swiper-slide-shadow-left {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-right {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-top {
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .swiper-container-3d .swiper-slide-shadow-bottom {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

/* CSS Mode */
.swiper-container-css-mode > .swiper-wrapper {
  overflow: auto;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */ }
  .swiper-container-css-mode > .swiper-wrapper::-webkit-scrollbar {
    display: none; }

.swiper-container-css-mode > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: start start; }

.swiper-container-horizontal.swiper-container-css-mode > .swiper-wrapper {
  scroll-snap-type: x mandatory; }

.swiper-container-vertical.swiper-container-css-mode > .swiper-wrapper {
  scroll-snap-type: y mandatory; }

.swiper-container-cube {
  overflow: visible; }
  .swiper-container-cube .swiper-slide {
    pointer-events: none;
    backface-visibility: hidden;
    z-index: 1;
    visibility: hidden;
    transform-origin: 0 0;
    width: 100%;
    height: 100%; }
    .swiper-container-cube .swiper-slide .swiper-slide {
      pointer-events: none; }
  .swiper-container-cube.swiper-container-rtl .swiper-slide {
    transform-origin: 100% 0; }
  .swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-active .swiper-slide-active {
    pointer-events: auto; }
  .swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-next, .swiper-container-cube .swiper-slide-prev, .swiper-container-cube .swiper-slide-next + .swiper-slide {
    pointer-events: auto;
    visibility: visible; }
  .swiper-container-cube .swiper-slide-shadow-top, .swiper-container-cube .swiper-slide-shadow-bottom, .swiper-container-cube .swiper-slide-shadow-left, .swiper-container-cube .swiper-slide-shadow-right {
    z-index: 0;
    backface-visibility: hidden; }
  .swiper-container-cube .swiper-cube-shadow {
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.6;
    -webkit-filter: blur(50px);
    filter: blur(50px);
    z-index: 0; }

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  transition-timing-function: ease-out; }

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  transition-property: opacity; }
  .swiper-container-fade .swiper-slide .swiper-slide {
    pointer-events: none; }

.swiper-container-fade .swiper-slide-active, .swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-flip {
  overflow: visible; }
  .swiper-container-flip .swiper-slide {
    pointer-events: none;
    backface-visibility: hidden;
    z-index: 1; }
    .swiper-container-flip .swiper-slide .swiper-slide {
      pointer-events: none; }
  .swiper-container-flip .swiper-slide-active, .swiper-container-flip .swiper-slide-active .swiper-slide-active {
    pointer-events: auto; }
  .swiper-container-flip .swiper-slide-shadow-top, .swiper-container-flip .swiper-slide-shadow-bottom, .swiper-container-flip .swiper-slide-shadow-left, .swiper-container-flip .swiper-slide-shadow-right {
    z-index: 0;
    backface-visibility: hidden; }

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  transform-origin: 50%;
  animation: swiper-preloader-spin 1s infinite linear;
  box-sizing: border-box;
  border: 4px solid black;
  border-radius: 50%;
  border-top-color: transparent; }

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg); } }

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: calc($swiper-navigation-size / 44 * 27);
  height: 44px;
  margin-top: calc(-1 * $swiper-navigation-size / 2);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; }
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none; }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: 44px;
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1; }

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 10px;
  right: auto; }
  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    content: 'prev'; }

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 10px;
  left: auto; }
  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after {
    content: 'next'; }

.swiper-button-lock {
  display: none; }

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10; }
  .swiper-pagination.swiper-pagination-hidden {
    opacity: 0; }

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%; }

/* Bullets */
.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0; }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transform: scale(0.33);
    position: relative; }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
    transform: scale(1); }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
    transform: scale(1); }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
    transform: scale(0.66); }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
    transform: scale(0.33); }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    transform: scale(0.66); }
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
    transform: scale(0.33); }

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2; }
  button.swiper-pagination-bullet {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    appearance: none; }
  .swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer; }

.swiper-pagination-bullet-active {
  opacity: 1;
  background: white; }

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0); }
  .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 6px 0;
    display: block; }
  .swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    top: 50%;
    transform: translateY(-50%);
    width: 8px; }
    .swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
      display: inline-block;
      transition: 200ms transform, 200ms top; }

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px; }

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap; }
  .swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: 200ms transform, 200ms left; }

.swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: 200ms transform, 200ms right; }

/* Progress */
.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute; }
  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: white;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transform-origin: left top; }
  .swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    transform-origin: right top; }
  .swiper-container-horizontal > .swiper-pagination-progressbar,
  .swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0; }
  .swiper-container-vertical > .swiper-pagination-progressbar,
  .swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    width: 4px;
    height: 100%;
    left: 0;
    top: 0; }

.swiper-pagination-blue {
  --swiper-pagination-color: #007bff; }

.swiper-pagination-indigo {
  --swiper-pagination-color: #6610f2; }

.swiper-pagination-purple {
  --swiper-pagination-color: #6f42c1; }

.swiper-pagination-pink {
  --swiper-pagination-color: #e83e8c; }

.swiper-pagination-red {
  --swiper-pagination-color: #dc3545; }

.swiper-pagination-orange {
  --swiper-pagination-color: #fd7e14; }

.swiper-pagination-yellow {
  --swiper-pagination-color: #ffc107; }

.swiper-pagination-green {
  --swiper-pagination-color: #28a745; }

.swiper-pagination-teal {
  --swiper-pagination-color: #20c997; }

.swiper-pagination-cyan {
  --swiper-pagination-color: #17a2b8; }

.swiper-pagination-white {
  --swiper-pagination-color: #fff; }

.swiper-pagination-gray {
  --swiper-pagination-color: #6c757d; }

.swiper-pagination-gray-dark {
  --swiper-pagination-color: #343a40; }

.swiper-pagination-lock {
  display: none; }

/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1); }
  .swiper-container-horizontal > .swiper-scrollbar {
    position: absolute;
    left: 1%;
    bottom: 3px;
    z-index: 50;
    height: 5px;
    width: 98%; }
  .swiper-container-vertical > .swiper-scrollbar {
    position: absolute;
    right: 3px;
    top: 1%;
    z-index: 50;
    width: 5px;
    height: 98%; }

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0; }

.swiper-scrollbar-cursor-drag {
  cursor: move; }

.swiper-scrollbar-lock {
  display: none; }

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; }
  .swiper-zoom-container > img, .swiper-zoom-container > svg, .swiper-zoom-container > canvas {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; }

.swiper-slide-zoomed {
  cursor: move; }

.footer {
  padding-top: 6.2rem;
  padding-bottom: 6.2rem;
  background-color: rgba(54, 54, 54, 0.9);
  font-weight: 300; }
  .footer-logo {
    width: 134px;
    height: 57px;
    background-size: contain;
    margin: 0 auto 3.1rem; }
  .footer .menu {
    position: relative;
    margin-bottom: 3.1rem; }
    .footer .menu a {
      font-size: 2.2rem;
      color: white;
      text-decoration: none;
      line-height: 3.3rem;
      text-transform: uppercase;
      position: relative;
      font-weight: 300; }
      @media (max-width: 991.98px) {
        .footer .menu a {
          width: 100%;
          text-align: center;
          padding-top: 1.3rem;
          padding-bottom: 1.3rem; }
          .footer .menu a:after {
            content: '';
            position: absolute;
            height: 2px;
            width: 2.5rem;
            background-color: #ec6608;
            left: 50%;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            bottom: 0; }
          .footer .menu a:nth-child(1) {
            padding-top: 0; }
          .footer .menu a:nth-last-child(1) {
            padding-bottom: 0; }
            .footer .menu a:nth-last-child(1):after {
              display: none; } }
      .footer .menu a span {
        color: #ec6608;
        padding: 0 10px;
        text-decoration: auto; }
        @media (max-width: 991.98px) {
          .footer .menu a span {
            display: none; } }
  .footer .extra-menu {
    position: relative;
    font-size: 1.8rem;
    line-height: 3.3rem;
    color: white; }
    @media (max-width: 991.98px) {
      .footer .extra-menu {
        text-align: center; } }
    .footer .extra-menu span {
      padding: 0 5px; }
  .footer .socials {
    position: relative;
    margin-bottom: 3.1rem; }
    .footer .socials .social {
      margin: 0 1.85rem; }

.copyright {
  background-color: #ec6608;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem; }
  @media (max-width: 991.98px) {
    .copyright {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem; } }
  .copyright p {
    color: white; }
    @media (max-width: 991.98px) {
      .copyright p {
        width: 100%;
        text-align: center;
        justify-content: center; } }
    .copyright p a {
      color: white;
      text-decoration: none; }
      @media (max-width: 991.98px) {
        .copyright p a {
          width: 100%;
          text-align: center; } }
    .copyright p .algv {
      margin-left: 3.1rem; }
      @media (max-width: 991.98px) {
        .copyright p .algv {
          width: 100%;
          margin-left: 0;
          text-align: center;
          margin-top: 1.3rem; } }
    .copyright p .priv {
      margin-left: 3.1rem; }
      @media (max-width: 991.98px) {
        .copyright p .priv {
          width: 100%;
          margin-left: 0;
          text-align: center;
          margin-top: 1.3rem; } }

@media (max-width: 991.98px) {
  .svg-logo {
    justify-content: center;
    margin-top: 2.5rem; } }

@media (max-width: 991.98px) {
  .svg-logo a {
    justify-content: center; } }

.header h1 {
  opacity: 0;
  height: 0;
  overflow: hidden;
  margin-bottom: 0;
  margin-top: 0; }

.header h2 {
  text-align: right;
  font-weight: 300; }

.header-home .header--inner {
  height: 0;
  padding-top: 52.65%; }
  @media (max-width: 991.98px) {
    .header-home .header--inner {
      max-width: calc(100% - 2.5rem);
      margin: 0 auto;
      padding-top: 50.65%; } }
  .header-home .header--inner::before {
    display: none; }

.header--inner {
  height: 70rem;
  position: relative;
  background-color: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 991.98px) {
    .header--inner {
      height: 40rem; } }
  .header--inner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 415px;
    background: transparent linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.114) 87%, rgba(0, 0, 0, 0) 100%) 0% 0% no-repeat padding-box;
    opacity: 0.55; }
    @media (max-width: 991.98px) {
      .header--inner::before {
        height: 10rem; } }
  .header--inner__content {
    width: 100%; }
    .header--inner__content--left, .header--inner__content--right {
      position: relative;
      width: 50%;
      min-height: 188px;
      z-index: 1;
      margin-top: 5.6rem;
      margin-bottom: 14rem; }
      .header--inner__content--left .label .rounded-block, .header--inner__content--right .label .rounded-block {
        padding-right: 6.2rem;
        padding-top: 3.7rem;
        padding-bottom: 3.7rem;
        padding-left: 9.3rem; }
        @media (max-width: 991.98px) {
          .header--inner__content--left .label .rounded-block, .header--inner__content--right .label .rounded-block {
            padding-top: 2.5rem;
            padding-bottom: 2.5rem;
            padding-left: 2.5rem;
            padding-right: 2.5rem; } }
      .header--inner__content--left .overlap, .header--inner__content--right .overlap {
        background-color: rgba(236, 103, 8, 0.9);
        position: absolute;
        top: 0;
        height: 100%;
        width: 6.2rem;
        z-index: 3; }
        .header--inner__content--left .overlap--corner, .header--inner__content--right .overlap--corner {
          position: relative;
          height: 100%; }
          .header--inner__content--left .overlap--corner--top, .header--inner__content--right .overlap--corner--top {
            position: absolute;
            top: -6.2rem;
            width: 0;
            height: 0;
            border-bottom: 6.2rem solid #ec6608;
            border-right: 6.2rem solid transparent; }
          .header--inner__content--left .overlap--corner--bottom, .header--inner__content--right .overlap--corner--bottom {
            position: absolute;
            bottom: -6.2rem;
            width: 0;
            height: 0;
            border-top: 6.2rem solid #ec6608;
            border-right: 6.2rem solid transparent; }
    .header--inner__content--right {
      width: auto;
      margin-right: 0;
      margin-left: auto; }
      @media (max-width: 991.98px) {
        .header--inner__content--right {
          max-width: calc(100% - 2.5rem);
          min-height: unset;
          margin-top: 10rem;
          margin-bottom: 10rem; } }
      .header--inner__content--right .overlap {
        right: 0;
        transform: translateX(6.2rem);
        -webkit-transform: translateX(6.2rem);
        -moz-transform: translateX(6.2rem);
        -ms-transform: translateX(6.2rem);
        -o-transform: translateX(6.2rem); }
    .header--inner__content--left {
      width: 61%;
      margin-right: auto;
      margin-left: 0; }
      .header--inner__content--left .magnifying {
        position: absolute;
        top: 0px;
        right: 0px;
        height: calc(100% - 4px);
        padding-top: 184px;
        width: 184px;
        background: whitesmoke;
        border-radius: 100%;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        -ms-border-radius: 100%;
        -o-border-radius: 100%;
        border: 2px solid #ec6608; }
        .header--inner__content--left .magnifying--image {
          position: absolute;
          right: 206px;
          bottom: 127px;
          height: 360px;
          width: 360px;
          border-radius: 100%;
          -webkit-border-radius: 100%;
          -moz-border-radius: 100%;
          -ms-border-radius: 100%;
          -o-border-radius: 100%;
          background: whitesmoke;
          border: 2px solid #ec6608; }
        .header--inner__content--left .magnifying--light {
          width: 414px;
          padding-top: 414px;
          background-color: rgba(255, 255, 255, 0.65);
          clip-path: polygon(41% 0, 96% 64%, 91% 80%, 65% 97%, 0 69%, 0 69%);
          bottom: 0;
          right: 0;
          position: absolute;
          z-index: -1; }
      .header--inner__content--left .overlap {
        left: 0;
        transform: translateX(-6.2rem);
        -webkit-transform: translateX(-6.2rem);
        -moz-transform: translateX(-6.2rem);
        -ms-transform: translateX(-6.2rem);
        -o-transform: translateX(-6.2rem); }
        .header--inner__content--left .overlap--corner--top {
          border-bottom: 6.2rem solid #ec6608;
          border-right: unset;
          border-left: 6.2rem solid transparent; }
        .header--inner__content--left .overlap--corner--bottom {
          border-top: 6.2rem solid #ec6608;
          border-right: unset;
          border-left: 6.2rem solid transparent; }

/*Navigation styling*/
#navigation {
  width: 100%;
  height: auto;
  position: fixed;
  background-color: transparent;
  z-index: 100;
  -webkit-transition: background-color .4s ease-in-out;
  -o-transition: background-color .4s ease-in-out;
  transition: background-color .4s ease-in-out;
  top: 0;
  left: 0;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px); }

#navigation.fixed-header {
  background-color: rgba(236, 103, 8, 0.9); }
  @media (max-width: 1199.98px) {
    #navigation.fixed-header {
      height: 6.2rem; } }

.fixed-header .navbar-nav .nav-link {
  color: #000; }

.fixed-header img.logo {
  /*-webkit-filter: invert(1);*/
  /*filter: invert(1);*/
  height: 30px; }

nav.navbar {
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row;
  flex-flow: row; }

.nav-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start; }
  @media (max-width: 1199.98px) {
    .nav-column li {
      padding-top: 2.5rem !important;
      padding-bottom: 2.5rem !important; } }

.left-column {
  margin-right: 10%;
  position: absolute;
  left: unset;
  right: 50%; }
  @media (max-width: 1199.98px) {
    .left-column {
      right: unset;
      position: relative;
      width: 100%;
      margin-right: 0;
      flex-direction: column; } }
  .left-column li:nth-last-child(1) {
    padding-right: 0; }

.right-column {
  margin-left: 10%;
  position: absolute;
  left: 50%; }
  @media (max-width: 1199.98px) {
    .right-column {
      left: unset;
      position: relative;
      width: 100%;
      margin-left: 0;
      flex-direction: column; } }
  .right-column li:nth-last-child(1) {
    padding-right: 0; }

.navbar-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center; }

.navbar-brand {
  height: 174px;
  z-index: 100;
  position: absolute;
  margin: 0;
  -webkit-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out; }
  @media (max-width: 1199.98px) {
    .navbar-brand {
      height: 6.2rem;
      position: relative;
      width: 7rem; } }

.fixed-header .navbar-toggler, .menu-open .navbar-toggler {
  top: 1.5rem; }

.fixed-header .navbar-brand, .menu-open.fixed-header .navbar-brand, .menu-open .navbar-brand {
  height: 6.2rem;
  width: 7rem;
  margin-top: 0; }

.navbar-collapse {
  position: relative;
  width: 80%;
  float: right;
  padding: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto; }

:focus {
  outline: none !important; }

.navbar-nav .nav-link, .menu-open .navbar-nav .nav-link {
  color: #ffffff;
  -webkit-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out; }

.navbar-toggler {
  cursor: pointer;
  outline: none;
  display: none;
  padding-right: 0px;
  z-index: 100;
  right: 0;
  position: absolute;
  top: 1.5rem;
  background-color: transparent;
  border: none;
  -webkit-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out; }
  @media (max-width: 1199.98px) {
    .navbar-toggler {
      display: block;
      height: 27px; } }

li.mobile {
  display: none; }

@media (max-width: 1199.98px) {
  .navbar-inner {
    height: 100%;
    justify-content: space-between; }
  li.mobile {
    display: block; }
    li.mobile .label {
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      left: -2.5rem; }
      li.mobile .label h2 {
        color: black; }
      li.mobile .label .overlap {
        opacity: 0;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
      li.mobile .label .rounded-block {
        background-color: transparent;
        padding-left: 2.5rem;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
    li.mobile.active .label .overlap {
      opacity: 0; }
    li.mobile.active .label .rounded-block {
      background-color: transparent; }
      li.mobile.active .label .rounded-block h2 {
        color: #ec6608; }
  li.desktop {
    display: none; }
  .navbar-nav::before {
    content: '';
    height: 100vh;
    width: calc(100% - 2.5rem);
    background-color: #f8f8f8;
    position: absolute;
    top: 0;
    left: 2.5rem; }
  .navbar-brand {
    position: relative;
    height: 6.2rem; }
    .navbar-brand img {
      transition: none; } }
    @media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
      .navbar-brand img {
        transition: none; } }

@media (max-width: 1199.98px) {
  .navbar-collapse {
    position: fixed;
    opacity: 1;
    height: 100vh;
    background: #ffffff;
    left: 0;
    width: 100%;
    transform: translateX(200vw);
    -webkit-transform: translateX(200vw);
    -moz-transform: translateX(200vw);
    -ms-transform: translateX(200vw);
    -o-transform: translateX(200vw);
    -webkit-transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
    -moz-transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
    -o-transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
    -ms-transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
    transition: all 0.5s cubic-bezier(0.4, 0, 1, 1); }
  .menu-open .navbar-collapse {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0); } }

.dropdown-menu.active {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: none;
  min-width: 100px;
  padding: 10px 0;
  margin: 0 0 0 0;
  background-color: #fff;
  border: 1px solid #B29863;
  border-radius: .25rem; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 5px 15px;
  clear: both;
  font-weight: 400;
  color: #B29863;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }

.dropdown-item:focus, .dropdown-item:hover {
  color: rgba(0, 123, 255, 0.84);
  text-decoration: none;
  background-color: #ffffff; }

.bars {
  position: relative;
  height: auto;
  width: 35px; }

.bar {
  width: 100%;
  height: 7px;
  background: #ffffff;
  margin-bottom: 6px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-transition: all .15s linear;
  -o-transition: all .15s linear;
  transition: all .15s linear; }

.bar.bar-3 {
  margin-bottom: 0; }

.menu-open .bars {
  margin: 0; }

.menu-open .navbar-brand {
  opacity: 1;
  visibility: hidden; }

.menu-open .bar {
  background-color: #cecece;
  margin-bottom: 2px; }

.menu-open .bar-1 {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.menu-open .bar-3 {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin-top: -18px; }

.menu-open .bar-2 {
  opacity: 0; }

.navbar {
  padding: 0; }

.navbar .plate--container {
  height: 100%; }

.fixed-header ul.navbar-nav {
  height: 63px; }

ul.navbar-nav {
  position: relative;
  width: 100%;
  background-color: rgba(26, 35, 52, 0);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row;
  flex-flow: row;
  justify-content: center;
  padding: 0px 0;
  -webkit-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out; }

.navbar-brand {
  overflow: hidden;
  width: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out; }
  @media (max-width: 1199.98px) {
    .navbar-brand {
      width: 7rem;
      margin-top: 0rem; } }

@media (max-width: 1199.98px) {
  .navbar .plate--container {
    padding-left: 5rem;
    padding-right: 5rem; }
  .menu-open .navbar .plate--container {
    padding-right: 2.5rem; }
  .menu-open .navbar-toggler {
    top: 2.5rem; }
  ul.navbar-nav {
    padding-left: 5rem;
    padding-right: 2.5rem;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 2.5rem; }
    ul.navbar-nav li.mobile {
      width: 100%;
      border-bottom: 1px solid #cecece; }
      ul.navbar-nav li.mobile.menu .overlap {
        opacity: 1;
        border-bottom: none; }
      ul.navbar-nav li.mobile.menu .rounded-block {
        padding-left: 2.5rem; }
      ul.navbar-nav li.mobile.menu h2 {
        text-transform: uppercase;
        font-size: 40px;
        line-height: 57px;
        color: white; }
      ul.navbar-nav li.mobile .label {
        margin-bottom: 0; } }

img.logo, img.fix-logo {
  height: 55px;
  max-height: 100%;
  width: 100%;
  -webkit-transition: all 0.5s cubic-bezier(0.78, 0.02, 0.24, 1.01);
  -o-transition: all 0.5s cubic-bezier(0.78, 0.02, 0.24, 1.01);
  transition: all 0.5s cubic-bezier(0.78, 0.02, 0.24, 1.01);
  -moz-transition: all 0.5s cubic-bezier(0.78, 0.02, 0.24, 1.01);
  -ms-transition: all 0.5s cubic-bezier(0.78, 0.02, 0.24, 1.01); }
  @media (max-width: 1199.98px) {
    img.logo, img.fix-logo {
      height: 3rem;
      width: 7rem; } }

/*.fixed-header img.fix-logo {*/
/*opacity: 1;*/
/*z-index: 1;*/
/*height: 86px;*/
/*}*/
ul.navbar-nav li {
  padding-right: 3.1rem;
  padding-top: 8.7rem;
  padding-bottom: 6.2rem;
  -webkit-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  list-style: none; }
  ul.navbar-nav li a {
    color: white;
    text-decoration: none;
    font-size: 2.2rem;
    line-height: 3.3rem;
    text-decoration: none; }

.fixed-header ul.navbar-nav li {
  padding-top: 15px;
  padding-bottom: 15px; }

ul.navbar-nav li.last {
  padding-right: 0; }

.fixed-header .navbar-brand {
  height: 63px; }

h2 {
  text-transform: uppercase;
  font-size: 40px;
  line-height: 57px; }
  @media (max-width: 1199.98px) {
    h2 {
      font-size: 2.2rem;
      line-height: 3.3rem; } }

@media (max-width: 1199.98px) {
  .plate--container {
    padding-left: 2.5rem;
    padding-right: 2.5rem; } }

@media (max-width: 991.98px) {
  .plate--column {
    min-height: 0px; } }

p {
  font-size: 1.8rem;
  line-height: 3.3rem; }
  @media (max-width: 991.98px) {
    p {
      font-size: 1.6rem;
      line-height: 3.3rem; } }

body b, body strong {
  font-weight: bold; }

.section-usp {
  width: 100%;
  max-width: 100vw;
  overflow: hidden; }

.section--inner {
  position: relative;
  margin-top: 6.2rem;
  margin-bottom: 6.2rem;
  padding-top: 6.2rem;
  padding-bottom: 6.2rem;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: row; }
  @media (max-width: 1199.98px) {
    .section--inner {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      margin-top: 5rem;
      margin-bottom: 0rem; } }
  .section--inner .description {
    padding-right: 6.2rem;
    padding-left: 6.2rem; }
    @media (max-width: 991.98px) {
      .section--inner .description {
        padding-right: 2.5rem;
        padding-left: 2.5rem; } }
  .section--inner .naw {
    padding-right: 6.2rem;
    padding-left: 6.2rem;
    margin-top: 3.1rem; }
    .section--inner .naw p:empty {
      display: none; }
    @media (max-width: 991.98px) {
      .section--inner .naw {
        padding-right: 2.5rem;
        padding-left: 2.5rem;
        margin-top: 2.5rem; } }
    .section--inner .naw p {
      line-height: 2.5rem; }
    .section--inner .naw .mail svg {
      height: 2.37rem;
      width: 3.1rem;
      margin-right: 2rem; }
    .section--inner .naw .tel svg, .section--inner .naw .adress svg {
      height: 3.1rem;
      width: 2.37rem;
      margin-right: 2rem; }
  .section--inner--background {
    padding-top: 12.4rem;
    padding-bottom: 12.4rem; }
    @media (max-width: 991.98px) {
      .section--inner--background {
        padding-top: 6.25rem;
        padding-bottom: 6.25rem;
        margin-top: 2.5rem;
        margin-bottom: 2.5rem; } }
    .section--inner--background .label {
      margin-bottom: 0; }
      .section--inner--background .label--blue .rounded-block {
        background-color: rgba(0, 95, 163, 0.8) !important;
        padding-top: 3.1rem;
        padding-bottom: 3.1rem; }
        @media (max-width: 991.98px) {
          .section--inner--background .label--blue .rounded-block {
            padding-top: 2.5rem;
            padding-bottom: 2.5rem; } }
    .section--inner--background .rounded-block .button {
      border-color: white;
      margin-left: 0;
      margin-top: 3.1rem; }
      @media (max-width: 991.98px) {
        .section--inner--background .rounded-block .button {
          margin-top: 1.3rem; } }
      .section--inner--background .rounded-block .button a {
        color: white; }
    .section--inner--background .section--inner-content {
      width: 65%; }
      @media (max-width: 991.98px) {
        .section--inner--background .section--inner-content {
          width: calc(100% - 2.5rem); } }
  .section--inner-content, .section--inner-image {
    width: 50%; }
    @media (max-width: 1199.98px) {
      .section--inner-content, .section--inner-image {
        width: 100%; } }
  .section--inner-content .button {
    margin-left: 6.2rem;
    margin-top: 6.2rem; }
    @media (max-width: 991.98px) {
      .section--inner-content .button {
        margin-top: 2.5rem;
        margin-left: 2.5rem; } }
  @media (max-width: 1199.98px) {
    .section--inner-content {
      order: 2; } }
  .section--inner.content--dark {
    background-color: #CECECE; }
  .section--inner-image {
    padding-right: 6.2rem; }
    @media (max-width: 1199.98px) {
      .section--inner-image {
        order: 1;
        height: 29.3rem;
        margin-bottom: -5rem;
        padding-right: 0; } }
    .section--inner-image-img {
      width: calc(100% - 3.1rem);
      margin-left: 3.1rem;
      margin-top: -12.4rem;
      height: calc(100% + 24.8rem);
      background-color: white;
      box-shadow: 4px 15px 20px -10px rgba(0, 0, 0, 0.32);
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      max-height: calc(600px + 24.8rem); }
      @media (max-width: 1199.98px) {
        .section--inner-image-img {
          order: 2;
          height: 100%;
          margin-top: -7.5rem;
          width: 100%;
          margin-left: 0;
          margin-right: 0; } }
    .section--inner-image-small .section--inner-image-img {
      margin-top: 0;
      height: 100%; }
    .section--inner-image--cover {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      padding-right: 0 !important;
      padding-left: 0 !important; }
      .section--inner-image--cover-img {
        height: 100%;
        width: 100%;
        box-shadow: none;
        margin-top: 0;
        margin-left: 0;
        filter: grayscale(1);
        -webkit-filter: grayscale(1); }
  .section--inner.content--img-right {
    flex-flow: row wrap; }
  .section--inner.content--img-left {
    flex-direction: row-reverse;
    flex-flow: row-reverse wrap; }
    .section--inner.content--img-left .section--inner-image {
      padding-right: 0;
      padding-left: 6.2rem; }
      .section--inner.content--img-left .section--inner-image-img {
        margin-left: 0; }
      @media (max-width: 1199.98px) {
        .section--inner.content--img-left .section--inner-image {
          padding-left: 0; } }

.section .swiper--buttons {
  position: absolute;
  left: 6.2rem;
  background-color: rgba(236, 103, 8, 0.9);
  bottom: 6.2rem;
  width: 15rem;
  height: 8.7rem;
  z-index: 44;
  border-radius: 0 168px 168px 0;
  -webkit-border-radius: 0 168px 168px 0;
  -moz-border-radius: 0 168px 168px 0;
  -ms-border-radius: 0 168px 168px 0;
  -o-border-radius: 0 168px 168px 0; }
  @media (max-width: 1199.98px) {
    .section .swiper--buttons {
      bottom: unset;
      top: 22.65rem;
      height: 4rem;
      width: 8rem;
      left: 2.5rem; } }
  .section .swiper--buttons .label {
    height: 100%;
    padding-right: 6.2rem;
    padding-left: 3.1rem; }
    .section .swiper--buttons .label .swiper-pagination {
      bottom: 0;
      position: relative;
      text-align: left;
      padding: 0 0 0 0;
      background-color: transparent;
      border-radius: 0 0 0 0;
      -webkit-border-radius: 0 0 0 0;
      -moz-border-radius: 0 0 0 0;
      -ms-border-radius: 0 0 0 0;
      -o-border-radius: 0 0 0 0;
      display: flex;
      color: white; }

.label {
  position: relative;
  display: flex;
  margin-bottom: 3.1rem;
  z-index: 3; }
  @media (max-width: 991.98px) {
    .label {
      margin-bottom: 2.5rem; } }
  .label .rounded-block {
    background-color: rgba(236, 103, 8, 0.9);
    display: inline-block;
    padding-left: 6.2rem;
    padding-right: 3.1rem;
    border-radius: 0 168px 168px 0;
    -webkit-border-radius: 0 168px 168px 0;
    -moz-border-radius: 0 168px 168px 0;
    -ms-border-radius: 0 168px 168px 0;
    -o-border-radius: 0 168px 168px 0; }
    .label .rounded-block h2 {
      color: white; }
    @media (max-width: 991.98px) {
      .label .rounded-block {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        min-height: 4rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem; } }
  .label .overlap {
    background-color: rgba(236, 103, 8, 0.9);
    position: absolute;
    top: 0;
    height: 100%;
    width: 6.2rem;
    z-index: 3; }
    @media (max-width: 991.98px) {
      .label .overlap {
        width: 2.5rem; } }
    .label .overlap--corner {
      position: relative;
      height: 100%; }
      .label .overlap--corner--top {
        position: absolute;
        top: -6.2rem;
        width: 0;
        height: 0;
        border-bottom: 6.2rem solid #ec6608;
        border-right: 6.2rem solid transparent; }
        @media (max-width: 991.98px) {
          .label .overlap--corner--top {
            top: -2.5rem;
            border-bottom: 2.5rem solid #ec6608;
            border-right: 2.5rem solid transparent; } }
      .label .overlap--corner--bottom {
        position: absolute;
        bottom: -6.2rem;
        width: 0;
        height: 0;
        border-top: 6.2rem solid #ec6608;
        border-right: 6.2rem solid transparent; }
        @media (max-width: 991.98px) {
          .label .overlap--corner--bottom {
            bottom: -2.5rem;
            border-top: 2.5rem solid #ec6608;
            border-right: 2.5rem solid transparent; } }
  @media (max-width: 1199.98px) {
    .label--big h2 {
      font-size: 1.8rem;
      line-height: 2.5rem; } }
  .label--big .overlap {
    width: 6.2rem; }
    @media (max-width: 991.98px) {
      .label--big .overlap {
        width: 2.5rem; } }
    .label--big .overlap--corner--top {
      top: -6.2rem;
      border-bottom: 6.2rem solid #ec6608;
      border-right: 6.2rem solid transparent; }
      @media (max-width: 991.98px) {
        .label--big .overlap--corner--top {
          top: -2.5rem;
          border-bottom: 2.5rem solid #ec6608;
          border-right: 2.5rem solid transparent; } }
    .label--big .overlap--corner--bottom {
      bottom: -6.2rem;
      border-top: 6.2rem solid #ec6608;
      border-right: 6.2rem solid transparent; }
      @media (max-width: 991.98px) {
        .label--big .overlap--corner--bottom {
          bottom: -2.5rem;
          border-top: 2.5rem solid #ec6608;
          border-right: 2.5rem solid transparent; } }
  .label--big.label--img-right .overlap {
    left: 0;
    transform: translateX(-6.2rem);
    -webkit-transform: translateX(-6.2rem);
    -moz-transform: translateX(-6.2rem);
    -ms-transform: translateX(-6.2rem);
    -o-transform: translateX(-6.2rem); }
    @media (max-width: 991.98px) {
      .label--big.label--img-right .overlap {
        transform: translateX(-2.5rem);
        -webkit-transform: translateX(-2.5rem);
        -moz-transform: translateX(-2.5rem);
        -ms-transform: translateX(-2.5rem);
        -o-transform: translateX(-2.5rem); } }
    .label--big.label--img-right .overlap--corner--top {
      border-bottom: 6.2rem solid #ec6608;
      border-right: unset;
      border-left: 6.2rem solid transparent; }
      @media (max-width: 991.98px) {
        .label--big.label--img-right .overlap--corner--top {
          border-bottom: 2.5rem solid #ec6608;
          border-right: unset;
          border-left: 2.5rem solid transparent; } }
    .label--big.label--img-right .overlap--corner--bottom {
      border-top: 6.2rem solid #ec6608;
      border-right: unset;
      border-left: 6.2rem solid transparent; }
      @media (max-width: 991.98px) {
        .label--big.label--img-right .overlap--corner--bottom {
          border-top: 2.5rem solid #ec6608;
          border-right: unset;
          border-left: 2.5rem solid transparent; } }
  .label--big.label--img-right.label--blue .rounded-block {
    padding-left: 6.2rem;
    padding-right: 12.4rem; }
    @media (max-width: 991.98px) {
      .label--big.label--img-right.label--blue .rounded-block {
        padding-left: 2.5rem;
        padding-right: 7.5rem; } }
  .label--big.label--img-right h2 {
    font-weight: 300; }
  .label--big.label--img-left .rounded-block {
    margin-left: auto;
    border-radius: 168px 0 0 168px;
    -webkit-border-radius: 168px 0 0 168px;
    -moz-border-radius: 168px 0 0 168px;
    -ms-border-radius: 168px 0 0 168px;
    -o-border-radius: 168px 0 0 168px; }
  .label--big.label--img-left .overlap {
    right: 0;
    transform: translateX(6.2rem);
    -webkit-transform: translateX(6.2rem);
    -moz-transform: translateX(6.2rem);
    -ms-transform: translateX(6.2rem);
    -o-transform: translateX(6.2rem); }
    @media (max-width: 991.98px) {
      .label--big.label--img-left .overlap {
        transform: translateX(2.5rem);
        -webkit-transform: translateX(2.5rem);
        -moz-transform: translateX(2.5rem);
        -ms-transform: translateX(2.5rem);
        -o-transform: translateX(2.5rem); } }
  .label--small .overlap {
    width: 3.1rem; }
    @media (max-width: 991.98px) {
      .label--small .overlap {
        width: 1.3rem; } }
    .label--small .overlap--corner--top {
      top: -3.1rem;
      border-bottom: 3.1rem solid #ec6608;
      border-right: 3.1rem solid transparent; }
      @media (max-width: 991.98px) {
        .label--small .overlap--corner--top {
          top: -1.3rem;
          border-bottom: 1.3rem solid #ec6608;
          border-right: 1.3rem solid transparent; } }
    .label--small .overlap--corner--bottom {
      bottom: -3.1rem;
      border-top: 3.1rem solid #ec6608;
      border-right: 3.1rem solid transparent; }
      @media (max-width: 991.98px) {
        .label--small .overlap--corner--bottom {
          bottom: -1.3rem;
          border-top: 1.3rem solid #ec6608;
          border-right: 1.3rem solid transparent; } }
  .label--small.label--img-right .overlap {
    left: 0;
    transform: translateX(-3.1rem);
    -webkit-transform: translateX(-3.1rem);
    -moz-transform: translateX(-3.1rem);
    -ms-transform: translateX(-3.1rem);
    -o-transform: translateX(-3.1rem); }
    @media (max-width: 991.98px) {
      .label--small.label--img-right .overlap {
        transform: translateX(-1.3rem);
        -webkit-transform: translateX(-1.3rem);
        -moz-transform: translateX(-1.3rem);
        -ms-transform: translateX(-1.3rem);
        -o-transform: translateX(-1.3rem); } }
    .label--small.label--img-right .overlap--corner--top {
      border-bottom: 3.1rem solid #ec6608;
      border-right: unset;
      border-left: 3.1rem solid transparent; }
      @media (max-width: 991.98px) {
        .label--small.label--img-right .overlap--corner--top {
          border-bottom: 1.3rem solid #ec6608;
          border-right: unset;
          border-left: 1.3rem solid transparent; } }
    .label--small.label--img-right .overlap--corner--bottom {
      border-top: 3.1rem solid #ec6608;
      border-right: unset;
      border-left: 3.1rem solid transparent; }
      @media (max-width: 991.98px) {
        .label--small.label--img-right .overlap--corner--bottom {
          border-top: 1.3rem solid #ec6608;
          border-right: unset;
          border-left: 1.3rem solid transparent; } }
  .label--small.label--img-right.label--blue .rounded-block {
    padding-left: 6.2rem;
    padding-right: 3.1rem; }
    @media (max-width: 991.98px) {
      .label--small.label--img-right.label--blue .rounded-block {
        padding-left: 2.5rem;
        padding-right: 2.5rem; } }
  .label--small.label--img-left .rounded-block {
    margin-left: auto;
    border-radius: 168px 0 0 168px;
    -webkit-border-radius: 168px 0 0 168px;
    -moz-border-radius: 168px 0 0 168px;
    -ms-border-radius: 168px 0 0 168px;
    -o-border-radius: 168px 0 0 168px; }
  .label--small.label--img-left .overlap {
    right: 0;
    transform: translateX(3.1rem);
    -webkit-transform: translateX(3.1rem);
    -moz-transform: translateX(3.1rem);
    -ms-transform: translateX(3.1rem);
    -o-transform: translateX(3.1rem); }
    @media (max-width: 991.98px) {
      .label--small.label--img-left .overlap {
        transform: translateX(1.3rem);
        -webkit-transform: translateX(1.3rem);
        -moz-transform: translateX(1.3rem);
        -ms-transform: translateX(1.3rem);
        -o-transform: translateX(1.3rem); } }
  .label--blue .rounded-block {
    background-color: #005ea3 !important;
    padding-left: 3.1rem;
    padding-right: 6.2rem; }
    @media (max-width: 991.98px) {
      .label--blue .rounded-block {
        padding-left: 2.5rem;
        padding-right: 2.5rem; } }
  .label--blue .overlap {
    background-color: #005ea3 !important; }
    .label--blue .overlap--corner--top {
      border-bottom-color: #004577 !important; }
    .label--blue .overlap--corner--bottom {
      border-top-color: #004577 !important; }

@media (max-width: 1199.98px) {
  .normal-slider {
    padding-left: 2.5rem;
    padding-right: 2.5rem; } }

.normal-slider .swiper-slide .section--inner-content {
  padding-left: 3.1rem;
  padding-right: 6.2rem; }
  @media (max-width: 991.98px) {
    .normal-slider .swiper-slide .section--inner-content {
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }
  .normal-slider .swiper-slide .section--inner-content h2 {
    margin-bottom: 0rem;
    padding-left: 6.2rem;
    padding-right: 6.2rem;
    text-align: center;
    color: #ec6608;
    width: 100%; }
    @media (max-width: 991.98px) {
      .normal-slider .swiper-slide .section--inner-content h2 {
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0; } }
  .normal-slider .swiper-slide .section--inner-content .description {
    margin-top: 6.2rem;
    padding-right: 0;
    padding-left: 0; }
    @media (max-width: 991.98px) {
      .normal-slider .swiper-slide .section--inner-content .description {
        text-align: center;
        margin-top: 2.5rem; } }
  .normal-slider .swiper-slide .section--inner-content .list {
    margin-top: 3.1rem; }
    .normal-slider .swiper-slide .section--inner-content .list ul {
      padding-left: 0;
      list-style: none; }
      .normal-slider .swiper-slide .section--inner-content .list ul li {
        font-size: 1.8rem;
        line-height: 3.3rem;
        margin-bottom: 1.55rem;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start; }
        @media (max-width: 991.98px) {
          .normal-slider .swiper-slide .section--inner-content .list ul li {
            font-size: 1.6rem; }
            .normal-slider .swiper-slide .section--inner-content .list ul li span {
              width: calc(100% - 1.7rem); } }
        .normal-slider .swiper-slide .section--inner-content .list ul li svg {
          margin-right: 1.6rem; }
          @media (max-width: 991.98px) {
            .normal-slider .swiper-slide .section--inner-content .list ul li svg {
              width: 1.7rem; } }
        .normal-slider .swiper-slide .section--inner-content .list ul li:nth-last-child(1) {
          margin-bottom: 0; }
  .normal-slider .swiper-slide .section--inner-content .button {
    margin-left: 0; }

.swiper-button {
  position: relative; }
  .swiper-button-next, .swiper-button-prev {
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%); }
    .swiper-button-next::after, .swiper-button-prev::after {
      content: ''; }
    @media (max-width: 991.98px) {
      .swiper-button-next, .swiper-button-prev {
        width: 2.5rem; } }
  .swiper-button-prev {
    left: 0px;
    transform: translateY(-50%) rotate(180deg);
    -webkit-transform: translateY(-50%) rotate(180deg);
    -moz-transform: translateY(-50%) rotate(180deg);
    -ms-transform: translateY(-50%) rotate(180deg);
    -o-transform: translateY(-50%) rotate(180deg); }
  .swiper-button-next {
    right: 0px; }
  .swiper-button-disabled {
    opacity: 0 !important; }

.usp-slider {
  overflow: visible;
  width: calc(100% + 6.2rem);
  margin-right: 0; }
  @media (max-width: 1199.98px) {
    .usp-slider {
      width: calc(100% - 2.5rem);
      margin-left: 0px;
      margin-right: unset; } }
  .usp-slider .swiper-slide {
    padding: 6.2rem;
    background-color: rgba(236, 103, 8, 0.9);
    position: relative;
    height: auto; }
    @media (max-width: 991.98px) {
      .usp-slider .swiper-slide {
        padding: 2.5rem 7.5rem; } }
    .usp-slider .swiper-slide .check {
      margin-bottom: 3.1rem; }
      @media (max-width: 991.98px) {
        .usp-slider .swiper-slide .check {
          margin-bottom: 1.3rem; } }
    .usp-slider .swiper-slide::after {
      content: '';
      position: absolute;
      left: 0;
      height: calc(100% - 6.2rem);
      width: 2px;
      top: 50%;
      background-color: white;
      transform: translateY(-50%) translateX(-50%);
      -webkit-transform: translateY(-50%) translateX(-50%);
      -moz-transform: translateY(-50%) translateX(-50%);
      -ms-transform: translateY(-50%) translateX(-50%);
      -o-transform: translateY(-50%) translateX(-50%); }
    .usp-slider .swiper-slide:nth-child(1) {
      border-radius: 168px 0 0 168px;
      -webkit-border-radius: 168px 0 0 168px;
      -moz-border-radius: 168px 0 0 168px;
      -ms-border-radius: 168px 0 0 168px;
      -o-border-radius: 168px 0 0 168px; }
      .usp-slider .swiper-slide:nth-child(1)::after {
        display: none; }
    .usp-slider .swiper-slide p {
      text-align: center;
      color: white;
      font-size: 2.2rem;
      line-height: 3.3rem;
      margin-bottom: 0; }

@media (max-width: 1600px) {
  .section--inner .naw .tel svg, .section--inner .naw .adress svg, .section--inner .naw .mail svg {
    width: 2.5rem;
    height: 2.5rem; }
  .naw .plate--column {
    margin-bottom: 1.3rem; } }

@media (max-width: 1600px) and (min-width: 1200px) {
  .section--inner .naw p {
    width: calc(100% - 4.5rem); } }

.button {
  display: inline-block;
  background: transparent;
  border: 2px solid #005ea3;
  border-radius: 0 30px 30px 0;
  -webkit-border-radius: 0 30px 30px 0;
  -moz-border-radius: 0 30px 30px 0;
  -ms-border-radius: 0 30px 30px 0;
  -o-border-radius: 0 30px 30px 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  overflow: hidden; }
  .button .button_link {
    color: #005ea3;
    padding: .8rem 3.1rem;
    display: block;
    font-weight: 700;
    text-decoration: none;
    font-size: 2.2rem;
    line-height: 3.3rem;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    background: transparent;
    outline: none;
    border: none; }
    @media (max-width: 1199.98px) {
      .button .button_link {
        padding: .2rem 1.3rem;
        font-size: 1.6rem; } }
  .button:hover {
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px; }
    .button:hover .button_link {
      background-color: #ec6608;
      color: white; }

.download {
  width: calc(1 / 2 * 100% - (6.2rem / 2));
  background-color: white;
  box-shadow: 4px 15px 20px -10px rgba(0, 0, 0, 0.32);
  padding-top: 44%;
  height: 0;
  margin-bottom: 6.2rem;
  position: relative; }
  @media (max-width: 767.98px) {
    .download {
      width: 100%;
      margin-bottom: 2.5rem;
      padding-top: 100%; } }
  .download::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #005ea3;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .download:hover::after {
    opacity: .65; }
  .download:hover .download-bottom {
    opacity: 1;
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px); }
  .download-wrapper {
    padding: 0 9.3rem;
    position: relative; }
    @media (max-width: 991.98px) {
      .download-wrapper {
        width: 100%;
        padding: 0 0rem; } }
  .download .label {
    position: absolute;
    top: 6.2rem; }
    @media (max-width: 991.98px) {
      .download .label {
        top: 3.1rem; } }
    .download .label .rounded-block {
      padding-left: 3.1rem; }
      .download .label .rounded-block h2 {
        font-size: 3.7rem;
        line-height: 5.3rem;
        font-weight: 300; }
  .download-bottom {
    position: absolute;
    bottom: 0;
    padding: 3.1rem;
    width: 100%;
    background-color: transparent;
    z-index: 3;
    opacity: 0;
    -webkit-transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
    -moz-transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
    -o-transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
    -ms-transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
    transition: all 0.5s cubic-bezier(0.4, 0, 1, 1);
    transform: translateY(20px);
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px); }
    .download-bottom svg {
      width: 8rem;
      height: 8rem;
      margin: 0 auto;
      display: flex; }
    .download-bottom p {
      color: white; }

@media (max-width: 991.98px) {
  .form-container .section--inner-image {
    max-width: calc(100% - 5rem);
    margin-right: auto;
    margin-left: auto; }
  .form-container .label {
    top: -23rem; } }

.form-container .section--inner-content {
  width: 100%; }

.form-container .section--inner .description {
  padding-right: 3.2rem;
  padding-left: 3.2rem; }
  .form-container .section--inner .description p {
    text-align: center; }
  @media (min-width: 992px) {
    .form-container .section--inner .description {
      width: 80%;
      margin: 0 auto;
      margin-bottom: 3.2rem; } }

.form-container .section--inner .naw {
  padding-right: 3.2rem;
  padding-left: 3.2rem; }
  @media (min-width: 992px) {
    .form-container .section--inner .naw {
      width: 80%;
      margin: 0 auto; } }

@media (min-width: 992px) {
  .form-container .section--inner .formulier {
    width: 80%;
    margin: 0 auto; } }

.formulier {
  margin-top: 3.2rem; }
  .formulier h3 {
    font-size: 2.2rem;
    line-height: 3.3rem;
    margin-bottom: 2.1rem;
    color: #ec6608;
    padding-left: 3.2rem;
    padding-right: 3.2rem; }
    @media (max-width: 991.98px) {
      .formulier h3 {
        padding-left: 2.5rem;
        padding-right: 2.5rem; } }
  .formulier .form {
    padding-left: 3.2rem;
    padding-right: 3.2rem; }
    @media (max-width: 991.98px) {
      .formulier .form {
        padding-left: 2.5rem;
        padding-right: 2.5rem; } }
    .formulier .form-lines {
      width: calc(50% - 1.55rem);
      margin-bottom: 1.55rem; }
      @media (max-width: 991.98px) {
        .formulier .form-lines {
          width: calc(100%); } }
      .formulier .form-lines:nth-last-child(1) {
        margin-bottom: 0; }
      .formulier .form-lines-textarea {
        width: 100%; }
      .formulier .form-lines label {
        margin-bottom: 10px;
        display: block;
        font-size: 1.8rem;
        line-height: 2.5rem; }
      .formulier .form-lines input, .formulier .form-lines textarea {
        width: 100%;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        outline: none;
        border: 2px solid #F8F8F8;
        padding: 0 1.55rem 0px;
        color: black;
        font-size: 1.8rem;
        line-height: 3.3rem;
        background-color: rgba(255, 255, 255, 0.9); }
      .formulier .form-lines input {
        height: 4rem; }
        .formulier .form-lines input::placeholder {
          color: rgba(0, 0, 0, 0.5); }
      .formulier .form-lines textarea {
        padding-top: 0rem;
        padding-bottom: 1.55rem;
        height: 11rem; }
      .formulier .form-lines .button {
        margin-left: 3.1rem; }
  .formulier .button {
    margin-left: 3.1rem;
    margin-top: 3.1rem; }

.paragraph h2 {
  color: #005ea3; }

.text {
  padding: 6.2rem 12.4rem;
  background-color: #cecece; }
  @media (max-width: 1199.98px) {
    .text {
      padding: 2.5rem; } }
  .text h1, .text h2 {
    margin-bottom: 2rem; }
  .text--blue h1, .text--blue h2, .text--blue h3, .text--blue h4, .text--blue h5, .text--blue h6 {
    color: #005ea3; }
  .text--orange h1, .text--orange h2, .text--orange h3, .text--orange h4, .text--orange h5, .text--orange h6 {
    color: #ec6608; }
  .text--overlap {
    margin-bottom: -6.2rem;
    padding-bottom: 12.4rem; }
    @media (max-width: 1199.98px) {
      .text--overlap {
        margin-bottom: -2.5rem;
        padding-bottom: 5rem; } }

/* VIDEO */
.video-element {
  --transition: all .5s ease;
  position: relative;
  width: 100%;
  overflow: hidden; }
  .video-element__inner {
    position: relative;
    height: 100%;
    width: calc(100% + 3px);
    left: -2px; }
  .video-element__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .video-element__video iframe {
      width: inherit;
      height: inherit; }
    .video-element__video.video--loaded {
      z-index: 2; }
  .video-element__thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden; }
  .video-element__placeholder, .video-element__thumbnail {
    width: 100%;
    height: 100%; }
  .video-element__placeholder {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center; }
    .video-element__placeholder picture {
      position: absolute;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit; }
    .video-element__placeholder img {
      object-fit: cover;
      -o-object-fit: cover;
      transform: scale(1.05);
      transition: var(--transition);
      position: absolute;
      top: 0; }
    .video-element__placeholder__img {
      height: 100%;
      width: 100%; }
  .video-element__play, .video-element img {
    width: 100%;
    height: 100%;
    transition: var(--transition); }
  .video-element__play {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    visibility: visible;
    opacity: 1; }
    .video-element__play__btn {
      width: 7.2rem;
      height: 7.2rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
      border-radius: 100%;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: var(--transition) !important; }
      .video-element__play__btn svg {
        height: 100%;
        width: auto; }
        .video-element__play__btn svg #circle {
          -webkit-transition: 0.3s 0.3s ease-in-out;
          -moz-transition: 0.3s 0.3s ease-in-out;
          -o-transition: 0.3s 0.3s ease-in-out;
          -ms-transition: 0.3s 0.3s ease-in-out;
          transition: 0.3s 0.3s ease-in-out; }
        .video-element__play__btn svg path {
          fill: #fff; }
    .video-element__play:before {
      content: "";
      position: absolute;
      width: 100% !important;
      opacity: .5;
      height: 100% !important;
      background-color: var(--clr-3);
      z-index: 1;
      transition: var(--transition) !important; }
  .video-element:hover .video-element__play__btn {
    transform: translate(-50%, -50%) scale(1.1);
    -webkit-transform: translate(-50%, -50%) scale(1.1);
    -moz-transform: translate(-50%, -50%) scale(1.1);
    -ms-transform: translate(-50%, -50%) scale(1.1);
    -o-transform: translate(-50%, -50%) scale(1.1);
    background-color: var(--clr-1); }
    .video-element:hover .video-element__play__btn svg #circle {
      opacity: 0; }
  .video-element:hover .video-element__play:before {
    background-color: rgba(236, 103, 8, 0.9); }
  .video-element:hover .video-element__placeholder img {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1); }
